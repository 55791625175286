







































































































import { defineComponent, ref } from '@vue/composition-api'
import { mapActions, mapState } from 'vuex'
import { TeachingTool } from '@/api/interfaces/teachingtool'
import { QueryParams } from '@/store/api-plateform-utils'
import {
  usePagination,
  PaginationOptions,
  parseQuery,
} from '@/composables/pagination'
import Pagination from '@/components/base/Pagination.vue'
import router from '@/router'
import deleteButton from '@/components/molecules/m-delete-button.vue'
import { userConnectedHasRole } from '@/composables/UserGrant'

export default defineComponent({
  setup (props, ctx) {
    const canDeleteTeachingTool = () => userConnectedHasRole('ROLE_DELETE_TEACHING_TOOL')
    const query = {
      ...router.app.$route.query,
    }
    const filters = ref({
      referenceSearched: query.referenceSearched || '',
      areaSearched: query.areaSearched || '',
    })
    delete query.referenceSearched
    delete query.areaSearched

    const totalItems = () => {
      return ctx.root.$store.state.teachingToolList.totalItems
    }
    const {
      paginationOptions,
      paginationRequestParams,
      nbPages,
      onSortBy,
      onSortDesc,
      }: PaginationOptions = usePagination(
    totalItems,
    parseQuery(query),
  ) as PaginationOptions

    return {
      paginationOptions,
      paginationRequestParams,
      nbPages,
      onSortBy,
      onSortDesc,
      filters,
      canDeleteTeachingTool,
    }
  },
  name: 'TeachingToolList',
  components: {
    deleteButton,
    ListTextFilter: () => import('@/components/base/ListTextFilter.vue'),
    ListSelectFilter: () => import('@/components/base/ListSelectFilter.vue'),
    Pagination,
  },
  data () {
    return {
      headers: [
        {
          text: this.$t('teaching_tool.list.headers.created_at'),
          align: 'start',
          value: 'createdAt',
        },
        {
          text: this.$t('teaching_tool.list.headers.name'),
          value: 'name',
          sortable: false,
        },
        {
          text: this.$t('teaching_tool.list.headers.brand'),
          value: 'brand',
          sortable: false,
        },
        {
          text: this.$t('teaching_tool.list.headers.reference'),
          value: 'reference',
          sortable: false,
        },
        {
          text: this.$t('teaching_tool.list.headers.area'),
          value: 'area.name',
          sortable: false,
        },
        {
          text: this.$t('teaching_tool.list.headers.actions'),
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapState('teachingToolList', {
      teachingToolList: 'list',
      loading: 'loading',
    }),
    ...mapState('area', {
      listAreas: 'list',
    }),
    baseRequest (): QueryParams {
      const request: Record<string, unknown> = {
        reference: this.filters.referenceSearched,
        area: this.filters.areaSearched,
        ...this.paginationRequestParams,
      }

      return request as QueryParams
    },
  },
  created () {
    this.load(this.baseRequest)
  },
  watch: {
    filters: {
      handler () {
        this.paginationOptions.page = 1
        this.loadData()
      },
      deep: true,
    },
    paginationOptions: {
      handler () {
        this.loadData()
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('teachingToolList', {
      load: 'load',
      deleteById: 'deleteById',
    }),
    goToTeachingToolForm (item: TeachingTool) {
      if (item.id) {
        this.$router.push({
          name: 'TeachingTool Edit',
          params: { idTeachingTool: item.id.toString() },
        })
      }
    },
    loadData () {
      this.addParamsToLocation()
      this.load(this.baseRequest)
    },
    addParamsToLocation () {
      let query: any = {
        ...this.filters,
        ...this.paginationOptions,
      }
      query = Object.fromEntries(
        Object.entries(query).filter(([key, value]) => !!value),
      )

      router.replace({ name: 'TeachingTool List', query: query })
    },
    filterByReference (value: string) {
      this.filters.referenceSearched = value
    },
    filterByArea (value: string) {
      this.filters.areaSearched = value
    },
  },
  beforeRouteLeave (to, from, next) {
    if (to.name === 'TeachingTool Edit' || to.name === 'TeachingTool Create') {
      this.$store.commit('navigation/resetPile')
      this.$store.commit('navigation/addRoute', from)
    }
    next()
  },
})
